.container {
    background-color: #1a1a1a;
    width: 80%;
    max-width: 1700px;
    margin: 0 auto;
}

.container h1 {
    margin-top: 50px;
    color: white !important;
    width: 60%;
    margin: 0 auto;
    font-size: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bolder;
    text-align-last: center;
}
.service {
    width: 80%;
    height: 300px;
    margin: 10%;
    border-radius: 10px;
    background-color: rgba(244, 114, 15, 0.32) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service h1 {
    text-align: center;
    width: 100%;
    border-bottom: none;
    color: white !important;
    font-size: 30px;
    padding: 0px;
}
.service p {
    color: white !important;
    text-align: center;
    width: 80%;
    color: white;
    font-size: 13px;
    font-weight: bold;
}
.service:nth-child(1) {
    background-image: url("../../../public/frota.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.service:nth-child(2) {
    background-image: url("../../../public/rigging.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.service:nth-child(3) {
    background-image: url("../../../public/carga.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.btn {
    font-weight: bolder !important;
    margin-top: 10px !important;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(244, 115, 15) !important;
    font-size: 16px !important;
    width: 60%;
}

@media only screen and (min-width: 1633px) {
    .title-service {
        font-size: 55px !important;
    }
    .service {
        margin: 4% 1% 4% 1%;
    }
} 
@media only screen and (max-width: 1633px) {
    .service {
        margin: 4% 1% 4% 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }
    .container {
        width: 100%;
    }
    .btn {
        width: 100%;
    }
    .service h1 {
        width: 100%;
        font-size: 29px;
    }
    .title-service {
        font-size: 40px;
    }
    
} 

@media only screen and (max-width: 900px) {
    .container {
        width: 80%;
    }
    .service {
        width: 80%;
        height: 300px;
        margin: 4% 1% 4% 1%;
        border-radius: 10px;
        background-color: rgba(244, 114, 15, 0.32) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .btn {
        width: 90%;
    }
} 