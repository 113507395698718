.footer-container {
    margin-top: 100px;
    border-top: 1px solid rgb(108, 108, 108);
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center
}
.footer-container p{
    color: white;
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
}