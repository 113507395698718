.title-client {
    margin-top: 50px !important;
    margin-bottom: 15px !important;
    font-size: 40px;
    color: white;
    text-align: center;    
}
.img-container {
    width: 80%;
}
.clients-container {
    margin: 40px auto;
    width: 80%;
    max-width: 700px;
    color: white;
    border-radius: 50px;
    padding: 20px;
    text-align: center;
    background-color: rgb(45 45 45) !important;
    display: flex;
    flex-direction: column;
}
.clients-container p {
    width: 85%;
    margin: 15px auto 25px auto;
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.caixa {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-image: url("../../../public/caixa.jpeg");
}
    
.brb {
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-color: white;
    background-image: url("../../../public/brb.png");
}
.petrobras {
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-color: white;
    background-image: url("../../../public/petrobras.jpeg");
}
.mc {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-image: url("../../../public/mc.png");
}
.parkshop {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-image: url("../../../public/parkshop.jpeg");
}
.brasal {
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-color: white;
    background-image: url("../../../public/brasal.png");
}
.senai {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-image: url("../../../public/senai.jpeg");
}
.gasbras {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    background-image: url("../../../public/gasbras.png");
}
    
    
@media only screen and (min-width: 1250px) {
    .title-client {
        font-size: 55px !important;
    }
    .clients-container {
        width: 50%;
    }
} 
    
@media only screen and (max-width: 600px) {
    .clients-container {
        width: 70%;
    }
    .caixa {
        height: 110px;
        background-size: 110%;
        background-color: white;
    }
    .parkshop {
        height: 110px;
        background-size: 110%;
        background-color: white;
    }
    .mc {
        height: 110px;
        background-size: 110%;
        background-color: red;
    }
    .petrobras {
        height: 110px;
        background-size: 98%;
        background-color: white;
    }
    .brasal {
        height: 110px;
        background-size: 98%;
        background-color: white;
    }
    .brb {
        height: 110px;
        background-size: 98%;
        background-color: white;
    }
    .gasbras {
        height: 110px;
        background-size: 98%;
        background-color: white;
    }
    .senai {
        height: 110px;
        background-size: 98%;
        background-color: rgb(218 37 28);
    }
} 