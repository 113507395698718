.container-banner {
    background-image: url("../../../public/banner.png");
    width: 100%;
    height: 610px;
    background-position: cover;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-repeat: no-repeat;

}
@media only screen and (min-width: 2101px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 1020px;    
    }
} 

@media only screen and (max-width: 2100px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 960px;    
    }
} 


@media only screen and (max-width: 1920px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 820px;    
    }
} 


 @media only screen and (max-width: 1750px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 770px;

    
    }
} 

 @media only screen and (max-width: 1500px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 650px;
    }
} 


 @media only screen and (max-width: 1250px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 510px;    
    }
} 

@media only screen and (max-width: 900px) {
    .container-banner {
        background-image: url("../../../public/bannerDesk.png");
        height: 380px;
    }
} 
 
@media only screen and (max-width: 565px) {
    .container-banner {
        background-image: url("../../../public/banner.png");
        height: 850px;
    }
} 
 
@media only screen and (max-width: 500px) {
    .container-banner {
        background-image: url("../../../public/banner.png");
        height: 750px;
    }
} 

@media only screen and (max-width: 440px) {
    .container-banner {
        background-image: url("../../../public/banner.png");
        height: 670px;
    }
} 
 
@media only screen and (max-width: 390px) {
    .container-banner {
        background-image: url("../../../public/banner.png");
        height: 600px;
    }
} 

@media only screen and (max-width: 350px) {
    .container-banner {
        background-image: url("../../../public/banner.png");
        height: 550px;  
    }
} 