
.header {
    background-color: #1a1a1a;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.563);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.header img {
    height: 60px;
    margin-right: 20px;
}

.title {

    display: flex;
    flex-direction: column;
}
.title h1 {
    font-size: 23px;
    margin: 0;
}
.title h2 {
    font-size: 15px;
    margin: 0;
    color: rgb(244, 115, 15);
}
