@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container-contact {
    margin: 40px auto;
    width: 80%;
    color: white;
    border-radius: 50px;
    text-align: center;
    background-color: rgb(45 45 45) !important;
    display: flex;
    flex-direction: column;
}

.text-container {
    width: 85%;
    margin: 15px auto;
}

.redes {
    display: flex;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
}

.telefone {
    margin-right: 5px;
    background-color: rgb(85 85 85 / 39%);
    border-radius: 20px;
    display: flex;
    padding: 13px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    flex: 1;
    height: 65px;
    text-align-last: center;
}

.endereco {
    background-color: rgb(85 85 85 / 39%);
    border-radius: 20px;
    display: flex;
    width: 78%;
    margin: 0 auto;
    padding: 13px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    height: 75px;
    text-align-last: center;
}

.instagram {
   background-color: rgb(85 85 85 / 39%);
    border-radius: 20px;
    display: flex;
    padding: 13px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    flex: 1;
    height: 65px;
    text-align-last: center;
}

.email {
    background-color: rgb(85 85 85 / 39%);
    border-radius: 20px;
    display: flex;
    padding: 13px;
    width: 78%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;;
    height: 65px;
    text-align-last: center;
}

.title-contact {
    margin-top: 30px !important;
    margin-bottom: 15px !important;
    font-size: 40px;
    color: white;
    text-align: center;    
}


.container-contact h1 {
    text-align: center;
    margin: 0px;
}

.container-contact p {
    color: white;
    font-size: 14px ;
    font-weight: 600;
    text-align: center;
}

.instagram div {
    background-size: contain;
    width: 35px;
    height: 35px;
    background-image: url("../../../public/instagramicon.png");
}

.email div {
    background-size: contain;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    background-image: url("../../../public/gmailicon.png");
}

.endereco div {
    background-size: contain;
    width: 35px;
    height: 35px;
    background-image: url("../../../public/googlemapsicon.png");
}

.telefone div {
    background-size: contain;
    width: 35px;
    height: 35px;
    background-image: url("../../../public/whatsappicone.png");
}


@media only screen and (min-width: 1250px) {
    .container-contact {
        width: 80%;
        max-width: 1050px;
        padding: 20px;
        margin-bottom: 100px ;
    }
    .title-contact {
        font-size: 55px !important;
    }
    .service {
        margin: 4% 1% 4% 1%;
    }
    .container-contact p {
        font-size: 18px ;
        font-weight: 500;
        width: 80%;
        text-align: left;
        margin: 4% auto;
    }
    .container-contact p {
        font-size: 18px ;
        font-weight: 500;
        width: 80%;
        text-align: left;
        margin: 1% auto;
    }
    .email {
        max-width: 540px;
    }
    .instagram {
        max-width: 255px;
    }
    .telefone {
        max-width: 255px;
    }
    .endereco {
        max-width: 540px;
    }
} 